<template>
  <div class="component selectable listitem" :class="{ active: selected == component, hovered: hovered == component }" :style="{ fontSize: component.settings.fontSize + 'px' }">
    <div class="component-type">{{ component.component }}</div>
    <ul><li>{{component.settings.content}}</li></ul>
  </div>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    component: {},
    selected: {},
    hovered: {}
  }
}
</script>

<style scoped lang="scss">
</style>