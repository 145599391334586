<template>
  <perfect-scrollbar class="editing">
    <h2>Editing</h2>
    <div v-if="!component">Select an item to edit.</div>
    <component v-else :is="component.component" :component="component" :key="component._uid">
    </component>
  </perfect-scrollbar>
</template>

<script>
import Paragraph from "./Paragraph/Settings.vue";
import Space from "./Space/Settings.vue";
import Figure from "./Figure/Settings.vue";
import Separator from "./Separator/Settings.vue";
import ListItem from "./ListItem/Settings.vue";
import Grid from "./Grid/Settings.vue";
import Button from "./Button/Settings.vue";
import Link from "./Link/Settings.vue";

export default {
  name: "EditPanel",
  props: {
    component: {},
  },
  components: {
    Paragraph, Space, Separator, Figure, ListItem, Grid, Button, Link
  }
};
</script>

<style scoped lang="scss">
</style>
