<template>
  <div class="c-form edit-form">
    <div class="form-group">
      <div class="title">Layer name</div>
      <input type="text" placeholder="Name" name="name" class="c-input" required v-model="component.name"/>
    </div>
    <div class="form-group">
      <div class="title">Height:</div>
      <vue-range-slider ref="slider" v-model="component.settings.height" tooltip="hover"></vue-range-slider>
    </div>
  </div>
</template>

<script>
export default {
  name: "Space",
  props: {
    component: {
      type: Object
    }
  }
};
</script>

<style scoped lang="scss">
</style>
