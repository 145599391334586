<template>
  <div id="neus">
    <!-- Modals -->

    <CreateProject v-if="createProjectModal" @createdProject="createProject" @closedModal="createProjectModal = false"/>

    <SettingsProject v-if="settingsProjectModal" :activeProject="this.activeProject" @closedModal="settingsProjectModal = false"/>

    <!-- Toolbar -->

    <div class="panel-file">
      <IconLogo/>
      <ul>
          <li>
            <a>File</a>
            <ul class="dropdown">
              <li><a @click.prevent="createProjectModal = true">New project</a></li>
              <li>
                <label for="upload" class="uploadButton">
                  <a>Open project...</a>
                  <input name="upload" id="upload" type="file" :value="openedProject" @change="openProject">
                </label>
              </li>
              <li><a @click.prevent="saveProject">Save project</a></li>
              <li><a @click.prevent="settingsProjectModal = true">Project settings...</a></li>
            </ul>
          </li>
          <li>
            <a>Edit</a>
            <ul class="dropdown">
              <li><a @click.prevent="copyComponent(selectedComponent)">Copy</a></li>
            </ul>
          </li>
          <li>
            <a>View</a>
            <ul class="dropdown">
              <li><a @click.prevent="codeView = !codeView">Code view <span v-if="codeView">(active)</span></a></li>
            </ul>
          </li>
          <li><a>Tools</a></li>
          <li><a>Help</a></li>
        </ul>
    </div>

    <!-- Tools -->

    <div class="panel-left">
      <ToolsPanel @createdComponent="createComponent"/>
    </div>

    <!-- Viewport -->

    <div class="panel-center">
      <div class="overlay" @click="selectedComponent = ''"></div>
      <div class="panel-top">
        <ul class="nav nav-tabs nav-justified">
          <li v-for="item in projects" :key="item._uid" :class="{ active: item == activeProject }">
            <a class="nav-link">
              <span v-if="item == activeProject">{{ item.title }}</span>
              <span v-else @click.prevent="setActiveProject(item)">{{ item.title }}</span>
              <button class="close" @click.prevent="closeProject(item)">
                <IconClose/>
              </button>
            </a>
          </li>
        </ul>
      </div>
      <div v-if="activeProject" class="responsive-view">
        <button :class="{ active: viewportWidth == 600 }" @click.prevent="viewportWidth = 600">Desktop</button>
        <button :class="{ active: viewportWidth == 320 }" @click.prevent="viewportWidth = 320">Mobile</button>
      </div>
      <div v-if="activeProject" class="zoom-view">
        <button :class="{ active: zoom == 0.25 }" @click.prevent="zoom = 0.25">25%</button>
        <button :class="{ active: zoom == 0.5 }" @click.prevent="zoom = 0.5">50%</button>
        <button :class="{ active: zoom == 1 }" @click.prevent="zoom = 1">100%</button>
      </div>

      <div id="viewport" class="viewport-wrapper" :style="{ width: viewportWidth + 'px', 'max-width': viewportWidth + 'px', zoom: zoom }">
        <div v-show="item == activeProject" class="viewport" v-for="item in projects" :key="item._uid" :style="{ width: item.width + 'px' }">
          <Viewport :components="item.content" :selected="selectedComponent" :hovered="hoveredComponent" @selectedComponent="selectComponent" @hoveredComponent="selectHoveredComponent" @mouseleave.native="hoveredComponent = ''"/>
        </div>
      </div>
    </div>

    <!-- Right Toolbar -->

    <div class="panel-right">
      <Multipane  layout="horizontal">
        <EditPanel :component="this.selectedComponent"/>
        <MultipaneResizer></MultipaneResizer>
        <LayerPanel v-if="this.activeProject" @mouseleave.native="hoveredComponent = ''" :components="activeProject.content" :selected="this.selectedComponent" :hovered="this.hoveredComponent" @selectedComponent="selectComponent" @removedComponent="removeComponent" @copiedComponent="copyComponent" @hoveredComponent="selectHoveredComponent"/>
      </Multipane>
    </div>

    <div class="code-view">
      <MonacoEditor v-if="this.codeView == true" style="position: fixed;left: 250px;top: 80px;"
      width="calc(100vw - 550px)"
      height="calc(100vh - 80px)"
      theme="vs-dark"
      language="javascript"
      @monacoInited="monacoInited"
      @change="onEditorChange"
      :options="options"
      :value="JSON.stringify(this.activeProject, undefined, 4)"
    ></MonacoEditor>
    </div>

    <vue-confirm-dialog></vue-confirm-dialog>
  </div>
</template>

<script>
// Imports

import './main.scss'
import Viewport from './components/Viewport.vue'
import CreateProject from './components/Project/Create.vue'
import SettingsProject from './components/Project/Settings.vue'
import EditPanel from './components/EditPanel.vue'
import LayerPanel from './components/LayerPanel.vue'
import ToolsPanel from './components/ToolsPanel.vue'
import IconClose from './components/Icons/Close.vue'
import IconLogo from './components/Icons/Logo.vue'
import { Multipane, MultipaneResizer } from 'vue-multipane'
import MonacoEditor from './monaco.vue';

export default {
  name: 'Neus',
  components: {
    IconClose, IconLogo, Viewport, CreateProject, SettingsProject, EditPanel, LayerPanel, ToolsPanel, Multipane, MultipaneResizer, MonacoEditor
  },
  mounted() {
    this.openLastProject(); // Set default project
  },
  methods: {
    monacoInited: function(editor) {
    },
    onEditorChange(value) {
    },

    // Component methods

    createComponent: function(component) {
      var newComponent = JSON.parse(JSON.stringify(component));

      var extendedComponent = Object.assign({
        _uid: generateUUID(),
        name: "Layer " + this.activeProject.layerCounter
      }, newComponent);
      this.activeProject.content.push(extendedComponent);

      this.activeProject.layerCounter++;

      var viewport = document.getElementById('viewport');
      viewport.scrollTo(0,viewport.scrollHeight);

      this.selectComponent(extendedComponent);
    },

    copyComponent: function(component) {
      var newComponent = JSON.parse(JSON.stringify(component));

      newComponent['_uid'] = generateUUID();
      newComponent['name'] = newComponent.name + " (Copy)";

      Array.prototype.insert = function(index, item) {
        this.splice(index, 0, item);
      };

      this.activeProject.content.insert(this.activeProject.content.indexOf(component)+1, newComponent);

      this.activeProject.layerCounter++;

      var viewport = document.getElementById('viewport');
      viewport.scrollTo(0,viewport.scrollHeight);

    setTimeout(() => {
      this.selectComponent(newComponent);
    }, 0)
      
    },

    selectComponent: function(component) {
      this.selectedComponent = component;
    },

    selectHoveredComponent: function(component) {
      this.hoveredComponent = component;
    },

    removeComponent: function(component) {
      this.activeProject.content.splice(this.activeProject.content.indexOf(component), 1);

      if(this.selectedComponent == component) {
        this.selectedComponent = '';
      }
    },

    // Project methods

    createProject: function(title, width, content) {
      var project = {
        _uid: generateUUID(),
        title: title,
        width: width,
        layerCounter: 1,
        content: content
      }

      this.projects.push(project);
      this.openLastProject();
      this.createProjectModal = false;
    },

    createOpenedProject: function(projectData) {
      var project = {
        _uid: generateUUID(),
        title: projectData.title,
        width: projectData.width,
        layerCounter: projectData.layerCounter,
        content: projectData.content
      }

      this.projects.push(project);
      this.openLastProject();
    },

    openProject: function(ev) {
      var file = ev.target.files[0];
      var reader = new FileReader();

      reader.onload = (e) => {
        var projectJson;

        if(file.name.match(/\.[0-9a-z]+$/i)[0] !== '.neu') {
         alert("Invalid file format!"); 
        }
        else {
        try {
          projectJson = JSON.parse(e.target.result);
          this.createOpenedProject(projectJson);
        } catch(e) {
          alert("Invalid file format!");
        }
        }
      }

      reader.readAsText(file);
      this.openedProject = '';
    },

    saveProject: function() {
      function download(data, filename, type) {
        var file = new Blob([data], {type: type});
        if (window.navigator.msSaveOrOpenBlob) // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else { // Others
        var file = new Blob([data], {type: type});
            var a = document.createElement("a"),
                    url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            console.log(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);  
            }, 0); 
        }
    }
    download(JSON.stringify(this.activeProject), this.activeProject.title + ".neu", "application/json")
    },

    openLastProject: function() {
      this.setActiveProject(this.projects[this.projects.length - 1]);
    },

    setActiveProject: function(project) {
      this.activeProject = project;
      this.selectedComponent = '';
    },

    closeProject: function(project) {
      this.$confirm(
        {
          message: `Do you want to close this project? All unsaved changes will be lost.`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: confirm => {
            if (confirm) {
              this.projects.splice(this.projects.indexOf(project), 1);
              this.openLastProject();
            }
          }
        }
      )
    },
  },
  data() {
    return {
      activeProject: '',
      createProjectModal: false,
      settingsProjectModal: false,
      selectedComponent: '',
      hoveredComponent: '',
      viewportWidth: 600,
      test: "testik",
      zoom: 1,
      openedProject: '',
      codeView: false,
      options: {
        formatOnType: true
      },
      projects: [
        { "_uid": "proj1", "title": "Untitled Project 1", "width": 600, "layerCounter": 1, "content": [ { "_uid": "abc", "component": "Paragraph", "name": "Hero title", "icon": "IconParagraph", "width": 600, "settings": { "style": "", "content": "Welcome.", "align": "center", "fontSize": "60", "fontColor": "#000000", "fontWeight": 200 } }, { "_uid": "8729e61e-8e9b-4a9a-b798-f3c1ce3c6c76", "name": "Hero subtitle", "icon": "IconParagraph", "component": "Paragraph", "settings": { "style": "", "content": "Start by adding elements from the tool panel.", "align": "center", "fontSize": "24", "fontColor": "#000000", "fontWeight": 300 } }, { "_uid": "307e5359-3b53-4c22-a8f8-b113d040f858", "name": "Space", "icon": "IconSpace", "component": "Space", "settings": { "height": "40" } }, { "_uid": "6b4690a7-3864-44bb-992d-1e08dd982bfd", "name": "Button", "icon": "IconButton", "component": "Button", "settings": { "style": "", "content": "Subscribe to our newsletter", "align": "center", "fontSize": 19, "backgroundColor": "#3d51fa", "fontColor": "#ffffff", "padding": 12, "link": "" } } ] }
      ],
      styles: [
        {
          _uid: "heading",
          name: "Heading 1",
          settings: {
            "fontSize": "42",
            "color": "blue"
          }
        }
      ]
    }
  }
}

// Generate unique ID function

function generateUUID() {
    var d = new Date().getTime();
    var d2 = (performance && performance.now && (performance.now()*1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;
        if(d > 0){
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

window.generateUUID = generateUUID;
</script>

<style lang="scss">
</style>
