<template>
  <div class="component link" :class="{ active: selected == component, hovered: hovered == component }" :style="{ textAlign: component.settings.align }">
    <div class="component-type">{{ component.component }}</div>
    <a target="_blank" :href="component.settings.link" class="c-link component selectable" :class="{ active: selected == component }" :style="{ fontSize: component.settings.fontSize + 'px', color: component.settings.fontColor }">{{component.settings.content}}</a>
  </div>
</template>

<script>
import VueResizable from 'vue-resizable'

export default {
  name: 'Link',
  props: {
    component: {},
    selected: {},
    hovered: {}
  }
}
</script>

<style scoped lang="scss">
a {
  display: block;
  pointer-events: none;
  text-decoration: none;
}
</style>