<template>
  <div class="component selectable figure" :class="{ active: selected == component, hovered: hovered == component }">
    <div class="component-type">{{ component.component }}</div>
    <img :src="component.settings.url">
  </div>
</template>

<script>
export default {
  name: 'Figure',
  props: {
    component: {},
    selected: {},
    hovered: {}
  }
}
</script>

<style scoped lang="scss">
</style>