<template>
  <div class="tools">
    <h2>Tools</h2>
    <button @click="createComponent('Paragraph')"><IconParagraph/> Text tool</button>
    <button @click="createComponent('Space')"><IconSpace/> Space tool</button>
    <button @click="createComponent('Separator')"><IconDivision/> Separator tool</button>
    <button @click="createComponent('Figure')"><IconImage/> Image tool</button>
    <button @click="createComponent('ListItem')"><IconList/> List Item tool</button>
    <button @click="createComponent('Button')"><IconButton/> Button tool</button>
    <button @click="createComponent('Link')"><IconLink/> Link tool</button>
  </div>
</template>

<script>
import IconButton from './Icons/Button.vue'
import IconDivision from './Icons/Division.vue'
import IconImage from './Icons/Image.vue'
import IconLink from './Icons/Link.vue'
import IconList from './Icons/List.vue'
import IconParagraph from './Icons/Paragraph.vue'
import IconSpace from './Icons/Space.vue'

export default {
  name: 'ToolsPanel',
  components: {
    IconButton, IconDivision, IconImage, IconLink, IconList, IconParagraph, IconSpace
  },
  created() {
    this.defaults = {
      Paragraph: {
        component: "Paragraph",
        icon: "IconParagraph",
        settings: {
          "style": "",
          "content": "Click here to change text.",
          "align": "left",
          "fontSize": "24",
          "fontColor": "#000000",
          "fontWeight": "400"
        }
      },
      Space: {
        component: "Space",
        icon: "IconSpace",
        settings: {
            "height": "40"
        }
      },
      Separator: {
        component: "Separator",
        icon: "IconDivision",
        settings: {}
      },
      Figure: {
        component: "Figure",
        icon: "IconImage",
        settings: {
          "style": "",
          "url": "https://via.placeholder.com/560x120",
          "align": "center"
        }
      },
      ListItem: {
        component: "ListItem",
        icon: "IconList",
        settings: {
          "style": "",
          "content": "Click here to change text.",
          "align": "center",
          "fontSize": "16"
        }
      },
      Button: {
        component: "Button",
        icon: "IconButton",
        settings: {
          "style": "",
          "content": "Click here to change text.",
          "align": "center",
          "fontSize": "24",
          "backgroundColor": "#000000",
          "fontColor": "#ffffff",
          "padding": "10",
          "link": ""
        }
      },
      Link: {
        component: "Link",
        icon: "IconLink",
        settings: {
          "style": "",
          "content": "Click here to change text.",
          "align": "left",
          "fontSize": "24",
          "fontColor": "#000000",
          "link": ""
        }
      }
    }
  },
  methods: {
    createComponent: function(component) {
      this.$emit('createdComponent', this.defaults[component]);
    }
  }
}
</script>

<style scoped lang="scss">
</style>
