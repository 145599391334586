<template>
  <div class="c-form edit-form">
    <div class="form-group">
      <div class="title">Layer name</div>
      <input type="text" placeholder="Name" name="name" class="c-input" required v-model="component.name"/>
    </div>
    <div class="form-group">
      <div class="title">Image URL</div>
      <input type="text" placeholder="URL" name="text" class="c-input" required v-model="component.settings.url"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Figure",
    props: {
    component: {
      type: Object
    }
  }
};
</script>

<style scoped lang="scss">
</style>
