<template>
  <div class="viewport-components">
    <Draggable :list="components" @start="drag=true" @end="drag=false">
      <template v-for="component in components">
        <component :is="component.component" :component="component" :key="component._uid" :selected="selected" :hovered="hovered" @click.native="openSettings(component)" @mouseover.native="selectHoveredComponent(component)">
        </component>
      </template>
    </Draggable>
  </div>
</template>

<script>
import Paragraph from './Paragraph/Component.vue'
import Space from './Space/Component.vue'
import Separator from './Separator/Component.vue'
import Figure from './Figure/Component.vue'
import ListItem from './ListItem/Component.vue'
import Grid from './Grid/Component.vue'
import Button from './Button/Component.vue'
import Link from './Link/Component.vue'
import Draggable from 'vuedraggable'

export default {
  name: 'Viewport',
  data() {
    return {
      editingComponent: ''
    }
  },
  props: {
    components: {},
    selected: {},
    hovered: {}
  },
  components: {
    Paragraph, Space, Separator, Figure, ListItem, Draggable, Grid, Button, Link
  },
  mounted() {
  },
  methods: {
    openSettings: function(component) {
      this.$emit('selectedComponent', component)
      this.editingComponent = component;
    },
    selectHoveredComponent: function(component) {
      this.$emit('hoveredComponent', component)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
