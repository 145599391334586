<template>
  <div class="component selectable grid" :class="{ active: selected == component, hovered: hovered == component }">
    <div class="component-type">{{ component.component }}</div>
    <component v-for="component in component.children" :is="component.component" :component="component" :key="component._uid" :selected="selected" @click.native="openSettings(component)"></component>
  </div>
</template>

<script>
import Paragraph from './../Paragraph/Component.vue'

export default {
  name: 'Grid',
  components: {
    Paragraph
  },
  data() {
    return {
      editingComponent: ''
    }
  },
  props: {
    component: {},
    selected: {},
    hovered: {}
  },
  methods: {
    openChild: function(component) {
      this.$emit('selectedChild', component)
      this.editingComponent = component;
    }
  }
}
</script>

<style scoped lang="scss">
</style>