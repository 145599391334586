<template>
  <div class="component selectable space" :class="{ active: selected == component, hovered: hovered == component }" :style="{ height: component.settings.height + 'px' }">
    <div class="component-type">{{ component.component }}</div>
  </div>
</template>

<script>
export default {
  name: 'Space',
  props: {
    component: {},
    selected: {},
    hovered: {}
  }
}
</script>

<style scoped lang="scss">
</style>
