<template>
  <div class="layer-item">
    <button class="layer" :class="{ active: selected == component, hovered: hovered == component }" @click.prevent="selectComponent(component)" @mouseover.prevent="selectHoveredComponent(component)">
      <span class="name"><component v-if="component.icon" :is="component.icon" :component="component.icon"></component> <span>{{component.name}}</span></span>
      <span class="copy" @click.prevent="copyComponent(component)" @mousedown.stop><IconCopy/></span>
      <span class="close" @click.prevent="removeComponent(component)" @mousedown.stop><IconClose/></span>
    </button>
  </div>
</template>

<script>
import IconClose from './Icons/Close.vue'
import IconLayer from './Icons/Layer.vue'
import IconButton from './Icons/Button.vue'
import IconDivision from './Icons/Division.vue'
import IconImage from './Icons/Image.vue'
import IconLink from './Icons/Link.vue'
import IconList from './Icons/List.vue'
import IconParagraph from './Icons/ParagraphLayer.vue'
import IconSpace from './Icons/Space.vue'
import IconCopy from './Icons/Copy.vue'

export default {
  name: 'Layer',
  props: {
    component: {},
    selected: {},
    hovered: {}
  },
  components: {
    IconClose, IconLayer, IconButton, IconDivision, IconImage, IconLink, IconList, IconParagraph, IconSpace, IconCopy
  },
  methods: {
    selectComponent: function(component) {
      this.$emit('selectedComponent', component);
    },
    removeComponent: function(component) {
      this.$emit('removedComponent', component);
    },
    copyComponent: function(component) {
      this.$emit('copiedComponent', component);
    },
    selectHoveredComponent: function(component) {
      this.$emit('hoveredComponent', component);
    }
  }
}
</script>

<style scoped lang="scss">
</style>
