<template>
  <div class="component selectable separator" :class="{ active: selected == component, hovered: hovered == component }">
    <div class="component-type">{{ component.component }}</div>
    <hr />
  </div>
</template>

<script>
export default {
  name: "Separator",
  props: {
    component: {},
    selected: {},
    hovered: {}
  },
};
</script>

<style scoped lang="scss">
hr {
  border: 0;
  border-top: 1px solid gainsboro;
  height: 10px;
}
</style>
