<template>
  <div class="c-form edit-form">
    <div class="form-group">
      <div class="title">Layer name</div>
      <input type="text" placeholder="Name" name="name" class="c-input" required v-model="component.name"/>
    </div>
    <div class="form-group">
      <div class="title">Text</div>
      <input ref="focusedInput" type="text" placeholder="Text" name="text" class="c-input" required v-model="component.settings.content"/>
    </div>
    <div class="form-group">
      <div class="title">Align</div>
      <select name="align" v-model="component.settings.align">
        <option value="left">Left</option>
        <option value="center">Center</option>
        <option value="right">Right</option>
      </select>
    </div>
    <div class="form-group">
      <div class="title">URL</div>
      <input type="text" placeholder="URL" name="url" class="c-input" required v-model="component.settings.link"/>
    </div>
    <div class="form-group">
      <div class="title">Font size</div>
      <vue-range-slider ref="slider" v-model="component.settings.fontSize" tooltip="hover"></vue-range-slider>
    </div>
    <div class="form-group">
      <div class="title">Font color</div>
      <div @click="pickerVisible = 'font'" class="color"><div class="colorpreview" :style="{ background: component.settings.fontColor }"></div> {{ component.settings.fontColor }}</div>
      <photoshop-picker v-if="pickerVisible == 'font'" v-model="component.settings.fontColor" @ok="ok" @cancel="cancel" :value="component.settings.fontColor" @input="updateValueFont" />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      pickerVisible: false
    }
  },
  name: "Link",
  props: {
    component: {
      type: Object
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.focusedInput.focus();
    }, 0)
  },
  methods: {
    updateValueFont: function(value) {
      this.component.settings.fontColor = value.hex;
    },
    ok: function() {
      this.pickerVisible = false;
    },
    cancel: function() {
      this.pickerVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
.vc-photoshop{
      position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #000;
}
.color{
      background: transparent;
    border: 1px solid #32343a;
    color: #fff;
    padding: 6px 10px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;

    &:hover {
      background:#32343a;
    }
}
.colorpreview{
      background: rgb(135, 33, 33);
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 4px;
}
</style>
