<template>
  <div class="project-create settings modal">
    <div class="c-form">
      <header>
        <h1>Project settings</h1>

        <button class="close" @click="closeModal">
          <IconClose/>
        </button>
      </header>

      <div class="form-group">
        <span>Project name</span>
        <input
          type="text"
          placeholder="Name"
          name="title"
          class="c-input"
          required
          v-model="activeProject.title"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from './../Icons/Close.vue'

export default {
  name: "Settings",
  props: {
    activeProject: {
      type: Object
    }
  },
  components: {
    IconClose
  },
  methods: {
    closeModal: function () {
      this.$emit("closedModal");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
label{
  cursor: pointer;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  h1 {
    margin: 0;
  }

  .close {
    width: 40px;
    height: 40px;
    margin: 0;
    svg {
      width: 40px;
      height: 40px;
    }
  }
}
.modal {
  color: #fff;
  .c-form {
    min-width: 600px;
  }
}
.form-group {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    background: transparent;
    border: 1px solid gray;
    color: #fff;
    padding: 6px 10px;
    border-radius: 5px;
  }
}
.c-button {
  background: #3d50fa;
  color: #fff;
  padding: 12px 20px;
  border: 0;
  border-radius: 12px;
  box-shadow: 0px 5px 5px #3d50fa40;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
}
h1 {
  margin-top: 0;
}
.cards {
    display: flex;
    flex-wrap: wrap;
    margin: 15px -5px 30px -5px;
    padding: 10px;
    border: 1px solid #36363a;
}
.card {
    flex-direction: column;
    text-align: center;
    margin: 5px;
    display: flex;
    align-items: flex-start;
    width: calc(25% - 10px);

  .content {
    margin-bottom: 10px;
  }
}
</style>
