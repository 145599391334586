<template>
    <img src="/assets/logo.png" alt="Neus" class="logo">
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style scoped>
.logo {
  width: 28px;
  margin-left: 9px;
  position: relative;
  top: -1px;
  margin-right: 10px;
}
</style>