<template>
  <div class="c-form edit-form">
    <div class="form-group">
      <div class="title">Layer name</div>
      <input type="text" placeholder="Name" name="name" class="c-input" required v-model="component.name"/>
    </div>
    <div class="form-group">
      <div class="title">Text</div>
      <input ref="focusedInput" type="text" placeholder="Text" name="text" class="c-input" required v-model="component.settings.content"/>
    </div>
    <div class="form-group">
      <div class="title">Size</div>
      <vue-range-slider ref="slider" v-model="component.settings.fontSize" tooltip="hover"></vue-range-slider>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListItem",
    mounted() {
    setTimeout(() => {
      this.$refs.focusedInput.focus();
    }, 0)
  },
    props: {
    component: {
      type: Object
    }
  }
};
</script>

<style scoped lang="scss">
</style>
