<template>
  <perfect-scrollbar class="layers">
    <h2>Layers</h2>
    <Draggable :list="components" @start="drag=true" @end="drag=false">
      <template v-for="component in components">
        <Layer :component="component" :key="component._uid" :selected="selected" :hovered="hovered" @selectedComponent="selectComponent" @removedComponent="removeComponent" @copiedComponent="copyComponent" @hoveredComponent="selectHoveredComponent"/>
      </template>
    </Draggable>
  </perfect-scrollbar>
</template>

<script>
import Layer from './Layer.vue'
import Draggable from 'vuedraggable'

export default {
  name: 'LayerPanel',
  props: {
    components: {},
    selected: {},
    hovered: {}
  },
  components: {
    Layer, Draggable
  },
  methods: {
    selectComponent: function(component) {
      this.$emit('selectedComponent', component)
    },
    removeComponent: function(component) {
      this.$emit('removedComponent', component)
    },
    copyComponent: function(component) {
      this.$emit('copiedComponent', component);
    },
    selectHoveredComponent: function(component) {
      this.$emit('hoveredComponent', component)
    }
  }
}
</script>

<style scoped lang="scss">
.sortable-ghost{
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.15);
}
</style>
