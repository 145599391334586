<template>
  <div class="component button" :class="{ active: selected == component, hovered: hovered == component }" :style="{ textAlign: component.settings.align }">
      <div class="component-type">{{ component.component }}</div>
      <a target="_blank" :href="component.settings.link" class="c-btn component selectable" :class="{ active: selected == component }" :style="{ fontSize: component.settings.fontSize + 'px', color: component.settings.fontColor, backgroundColor: component.settings.backgroundColor, padding: component.settings.padding + 'px' }">{{component.settings.content}}</a>
  </div>
</template>

<script>
import VueResizable from 'vue-resizable'

export default {
  name: 'Button',
  props: {
    component: {},
    selected: {},
    hovered: {}
  }
}
</script>

<style scoped lang="scss">
a {
  display: inline-block;
  pointer-events: none;
  text-decoration: none;
}
</style>