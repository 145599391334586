<template>
  <div class="c-form edit-form">
    <div class="form-group">
      <div>Layer name</div>
      <input type="text" placeholder="Name" name="name" class="c-input" required v-model="component.name"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Grid",
    props: {
    component: {
      type: Object
    }
  }
};
</script>

<style scoped lang="scss">
</style>
